import "./footer.css";
import packageJson from '../../package.json';

function Footer() {
  let dt = new Date();
  return (
    <>
      <div className="row footer mx-0">
      <div className="col-6">Copyright &copy; {dt.getFullYear()} MyDesk. All rights reserved.</div>
      <div className="col-6 text-right">Version {packageJson.ApplicationVersion} [{packageJson.VersionReleaseDate}]</div>
    </div>
    </>
  );
}

export default Footer;
