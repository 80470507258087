import React, { useRef, useEffect, useState } from "react";
import {faCancel, faCheckCircle, faEdit, faSave, faClose, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import useTable from "../../hooks/useTable";
import TableFooter from "./table-footer";
import useTable from "./use-table";
import { Link } from "react-router-dom";
import { cursorTo } from "readline";
import mainService from "../../service/main-service";
import Swal from "sweetalert2";
import DateField from "../general/date-field";
import { Toast } from "react-bootstrap";

const TableGrid = (props: any) => {
  // const { slice: tableSlice, range } = useTable(props?.data, page, props?.rowsPerPage);

  const wrapperRef = useRef<any>(null);

  useEffect(() => {
    getLanguageList();

    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        let currentElement: any = event.target;
        if (currentElement.className != "dropdown-item") {
          let openElements: any = document.getElementsByClassName(
            "table-sorting-header"
          );

          if (openElements && openElements.length > 0) {
            for (let i = 0; i < openElements.length; i++) {
              openElements[i].style.display = "none";
            }
          }
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const options = ["None", "Free", "Light", "Pro"];
  const showAppointmentExchangeList = [
    { value: "MyDeskOnly", caption: "MyDesk meetings only" },
    { value: "MyDeskAndTeams", caption: "MyDesk and Teams meetings" },
    { value: "All", caption: "All appointments" },
  ];
  const showAnonymizeAfterDays = [
    { value: 0, caption: "Never" },
    { value: 14, caption: "After 14 days" },
    { value: 30, caption: "1 Month" },
    { value: 90, caption: "3 Months" },
    { value: 180, caption: "6 Months" },
    { value: 365, caption: "1 Year" },
  ];
  const checkinAutobookingList = [
    { name: "Yes", value: true },
    { name: "Inherit from location", value: false },
  ];

  const [sliced, setSliced] = useState<any>([]);

  useEffect(() => {
    setSliced(props?.data);
    setFilterdList(props?.data)
  }, [props?.data]);

  function getTrialEndDate(dateTime: any) {
    let date = new Date(dateTime);
    if (dateTime) {
      let dd = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
      let MM =
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      return dd + "-" + MM + "-" + date.getFullYear();
    }
    return "";
  }

  const updateStartDate = (dt: string) => {
    setRowData({
      ...rowData,
      TrialEndDate: new Date(dt).toDateString(),
    });
  };

  let obj: any = {
    Name: "",
    //TenantId: "",
    SubDomain: "",
    Active: true,
    IsTrial: true,
    TrialEndDate: new Date(),
    Price: "0",
    EnableColleagueBooking: true,
    EnableManagerBooking: true,
    EnableMeetingRoom: false,
    AllowSpecifySeat: false,
    //MaxDaysOfBookingAhead: 14,
    //EmailSenderAddress: "",
    Domains: [],
    //MobileMenuShowQR: false,
    //MobileMenuShowCheckin: false,
    //AnonymizeAfterDays: 0,
    //WorkHoursStart: 8,
    //WorkHoursEnd: 17,
    //SaveReservationsInExchange: true,
    //ShowAppointmentsFromExchange: "All",
    //AllowAutoSelectSameSeat: false,
    //MaximumBookingsAllowed: 14,
    EnableCanteen: false,
    EnableDeskBooking: true,
    EnableParking: false,
    EnableGuestBookings: true,
    EnableSensors: false,
    //ShowGuestregistrationLink: false,
    //ShowInfoscreenLink: false,
    //ShowAnalyticsLink: false,
    //ShowPlannerLink: false,
    //OutOfOfficeTypes: [],
    //SearchADGroups: [],
    //AutobookingAlwaysCheckedIn: false,
    //EmailCateringToCostCenter: false,
    //SendReservationNotificationToGuest: false,
    //GuestRegistrationPreRegisterGuestsRegistered: false,
    //GuestRegistrationPreRegisterGuestsMeetings: false,

    //DisclaimerText: "",
    //TenantDomain: "",

    //DefaultLanguageId: languageDefaultId,
    FeatureEnableWorkTime: true,
    FeatureEnableGuestRegistration: true,
    FeatureEnablePlanner: true,
    FeatureEnableAnalytics: true,
    WorkTimeVersion: "",
    RoomAlyzerId: "",
  };

  const [isRowEditId, setIsRowEditId] = useState(-1);
  const [isRowEdit, setIsRowEdit] = useState(false);

  const [rowData, setRowData] = useState<any>(obj);

  const [openRowId, setOpenRowId] = useState(-1);

  function onRowLoadEdit(Id: any) {
    //debugger
    setOpenRowId(Id);
    let data = sliced.filter((x: any) => x.Id === Id);
    if (data.length > 0) {
      const ParamDomain: any[] = data[0].Domains;
      var domainsList = [];
      if (ParamDomain.length > 0) {
        for (let i = 0; i < ParamDomain.length; i++) {
          domainsList.push(ParamDomain[i].Name);
        }
      }
      delete data[0].ApiKey; // For deleting this props from obj
      delete data[0].SearchADGroups;
      delete data[0].SearchUseEmailInsteadOfUpn;
      delete data[0].UseDelegatedAuthenticationOnly;
      delete data[0].WorkFromHomeText;
      delete data[0].ConnectedTenantsCanAccessAllMeetingrooms;

      var updatedData = { ...data[0], Domains: domainsList };
    }

    if (openRowId === Id && isRowEditId === -1) {
      // Here is the similar code
      setIsRowEdit(true);
      setIsRowEditId(Id);
      setRowData(updatedData);
    } 
    else if (openRowId === Id) {
    } 
    else {
      setIsRowEditId(Id);
      setIsRowEdit(true);
      setRowData(updatedData);
    }
  }
  const handleFieldChange = (event: any) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    const fieldName = event.target.name;
    setRowData({ ...rowData, [fieldName]: value });
  };

  function cancelEdit(Id: any, rowsData: any) {
    setIsRowEditId(-1);
    setIsRowEdit(false);
  }
  async function EditTenant(event: any) {
    await mainService.editTenants(rowData);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Record has been Updated successfully",
      showConfirmButton: false,
      timer: 6000,
    });
    setIsRowEdit(false);
    setIsRowEditId(-1);
    window.location.reload();
  }
  function openDropdown(event: any, Id: string) {
    setIsRowEdit(false);
    let openElements: any = document.getElementsByClassName(
      "table-sorting-header"
    );
    if (openElements && openElements.length > 0) {
      for (let i = 0; i < openElements.length; i++) {
        if (
          (openElements[i].style.display =
            "block" && openElements[i].id != "dropdown-menu-" + Id)
        )
          openElements[i].style.display = "none";
      }
    }
    let ele = document.getElementById("dropdown-menu-" + Id);
    if (ele) {
      if (ele.style.display == "none") {
        ele.style.display = "block";
      } else {
        ele.style.display = "none";
      }
    }
  }
  const [FiledName, setFiledName] = useState<string>("Name");
  const [order, setOrder] = useState("Asce");

  function handleSortBy(event: any, sortby: string, fieldName: string, field: string) {
    setFiledName(fieldName);
    setOrder(sortby);
    openDropdown(event, field);
  }

  const [languageList, setLanguageList] = useState([]);
  async function getLanguageList() {
    let languageData: [] = await mainService.getLanguageList();
    setLanguageList(languageData);
  }

  function getLanguageName(defaultId: any) {
    var displayName: any = languageList.find(
      (item: any) => item.Id == defaultId
    );
    //setLanguageName(displayName.Displayname)
    return displayName.Displayname;
  }
  function getLanguageIcon(defaultId: any) {
    var displayName: any = languageList.find(
      (item: any) => item.Id == defaultId
    );
    //setLanguageName(displayName.Displayname)
    return displayName.Icon;
  }

  const [IsEmployeeViewDetails, setEmployeeviewOpen] = useState(false);
  function closeSidePanel() {
    document.body.classList.remove("pointeradd");
    setEmployeeviewOpen(false);
    setSelectedFieldValue([]);
    setFilterByArray([]);
    setFilterdList(sliced)
  }
  const [filterByFieldData, setFilterByFieldData] = useState <any>([]);
  const [headerForFilterBy, setheaderForFilterBy] = useState("");
  const [selectedFieldValue, setSelectedFieldValue] = useState<any>([]);
  const[filterByArray, setFilterByArray] = useState<any>([]);
  function openSidePanel(event: any, FilterField: string, closeToField: string) {
   
    let index = filterByArray.findIndex((x: any)=>x.FiledName == FilterField);
    if(index == -1) {
      setSelectedFieldValue([]);
    }
    document.body.classList.add("pointeradd");
    setEmployeeviewOpen(true);
    openDropdown(event, closeToField);
    setheaderForFilterBy(closeToField);
    setFiledName(FilterField);
    let data: any = [];
    for (var i = 0; i < sliced.length; i++) {
      const singleObj = sliced[i];
      let value = singleObj[FilterField];
      let newValue = singleObj[FilterField];
      if(FilterField == "DefaultLanguageId") {
         value = getLanguageName(newValue);
      }
      if(newValue && typeof newValue == "string" && newValue.indexOf("-") > -1 && newValue.split("-").length == 3) {
         value = newValue.split("T")[0];
      }
      if (value != null && value !== "undefined" && !data.includes(value)) {
        if (Array.isArray(value) && value.length > 0) {
          value.forEach(element => {
            if(!data.includes(element.Name)) {
              //if(element.Name != ''){
                data.push(element.Name);
              //}           
              }
              
          });
        } else if (!Array.isArray(value)) {
          data.push(value);
        }
      }
    }
    setFilterByFieldData(data);
    setGetFilterData(data);
  }

  const [query, setQuery] = useState("");

  const[getFilteredData, setGetFilterData] = useState<any>([]);

  function handleFilterbySearch(event: any) {
    const searchQuery = event.target.value;
    setQuery(searchQuery);

    let filteredData: any[] = [];
    const data: any[] = getFilteredData;

    if (event.target.value && event.target.value.length > 0) {
      filteredData = data.filter((x: any) => {
        return x?.toLowerCase().includes(event.target.value.toLowerCase());
      });
    } else {
      filteredData = filterByFieldData;
    }

    setGetFilterData(filteredData);
    //console.log("filteredData", filteredData)
  }
  
function handleFilterBySelected(event: any) {
  const checked = event.target.checked;
  const paramValue: any = event.target.value;
  
  let isDateCount = paramValue.split("-").length - 1;
  //console.log("getFilteredData", getFilteredData);

  let selectedData: any = [];
  let objList: any = filterByArray.filter((x: any)=> x.FieldName == FiledName);
  if(objList.length > 0) {
    selectedData = [...objList[0].FieldValue];
  }

  let value: any = paramValue;
  //let selectedData: any = [];//[...selectedFieldValue];

  var regex = /((^[0-9]+[a-z]+)|(^[a-z]+[0-9]+))+[0-9a-z]+$/i;
  if(paramValue && isDateCount == 2){
    value = paramValue;
    //console.log("value", value)
  }

  else if (!paramValue.match(regex) && !isNaN(parseInt(paramValue))) {
    value = parseInt(paramValue); 
  } 
  else if (paramValue === "true" || paramValue === "false") {
    value = paramValue === "true"; 
  }
  
  if (checked) {
    if (!selectedData.includes(value)) {
      selectedData.push(value);
    }
  } else {
    const index = selectedData.indexOf(value);
    if (index !== -1) {
      selectedData.splice(index, 1);
    }
  }
  //console.log("selectedData", selectedData)
  fillFilterByArray(FiledName, selectedData);
  setSelectedFieldValue(selectedData);
}

function handleApplyFilter(event:any){
  document.body.classList.remove("pointeradd");
  setEmployeeviewOpen(false);
}

function handleClearFilter(event:any){
  document.body.classList.remove("pointeradd");
  setEmployeeviewOpen(false);
  setSelectedFieldValue([]);
  setFilterByArray([]);
  setFilterdList(sliced)
}


/********************************multiple column filters******************************************/
const[filterdList, setFilterdList] = useState<any>([]);



function changeToClearFilter (){
  setSelectedFieldValue([]);
  setFilterByArray([]);
  setFilterdList(sliced)
  setFiledName("Name");
  setOrder("Asce");
}

function getDefaultChecked(item: any) {
  if (filterByArray && filterByArray.length > 0) {
    for (let i = 0; i < filterByArray.length; i++) {
      for (let j = 0; j < filterByArray[i].FieldValue.length; j++) {
        if (item == filterByArray[i].FieldValue[j]) {
          return true;
        }
      }
    }
    return false;
  }
  return false;
}


function fillFilterByArray(FieldName: string, selectedData: any) {
  let index = -1;
    if (filterByArray && filterByArray.length > 0) {
      index = filterByArray.findIndex((item: any) => item.FieldName === FieldName);
    }
    if (index > -1) {
      let updatedFieldData = [...filterByArray];
      updatedFieldData[index].FieldValue = [...selectedData];
      setFilterByArray(updatedFieldData);
    } else {
      const newData = { FieldName: FieldName, FieldValue: [...selectedData] };
      setFilterByArray((prevState: any) => [...prevState, newData]);
    }
    setSelectedFieldValue(selectedData);
}
function filterProcess() {
  if (filterByArray && filterByArray.length > 0) {
    let filteredData = sliced;
    for (let i = 0; i < filterByArray.length; i++) {
      if (filterByArray[i].FieldValue.length > 0) {
        filteredData = filteredData.filter((x: any) => {
          for (let j = 0; j < filterByArray[i].FieldValue.length; j++) {
            let value = filterByArray[i].FieldValue[j];
           
            if (typeof value === "string" && value.indexOf("-") > -1 && x[filterByArray[i].FieldName] && value.split("-").length === 3) {

              if ( x[filterByArray[i].FieldName].split("T")[0] == value ) {
                return true;
              }
            }
            else if ( x[filterByArray[i].FieldName] && Array.isArray(x[filterByArray[i].FieldName])) {
             
              if ( x[filterByArray[i].FieldName].some((item: any) => item.Name === value)) {
                return true;
              }
            }
            else if ( filterByArray[i].FieldName === "DefaultLanguageId"){
             
              if (getLanguageName(x[filterByArray[i].FieldName]) == value) {
                return true;
              }
            }
            else {
             
              if (x[filterByArray[i].FieldName] == value) {
                return true;
              }
            }
          }
          return false;
        });
      }
      else {
        //setFilteredDataToShow(sliced);
      }
    }
   
    if (filteredData.length > 0) {
      setFilterdList(filteredData);
      return
    } else {
      setFilterdList([]);
    }
  }
  /*let dataList: any = [...filterdList];
  if (filterByArray.length == 1) {
    dataList = [...sliced];
  }
 
  dataList = dataList.filter((x: any) => {
    console.log("x", x);
    for (let i = 0; i < filterByArray.length; i++) {
      console.log("i", i);
      let valueLength: number = filterByArray[i].FieldValue.length;
      if (valueLength > 0) {
        for (let j = 0; j < valueLength; j++) {
          
          let value = filterByArray[i].FieldValue[j];
          
          if (typeof value === "string" && value.indexOf("-") > -1 && value.split("-").length === 3) {
            if (x[filterByArray[i].FieldName].split("T")[0] === value) {
              return true;
            }
          } else if (filterByArray[i].FieldName === "DefaultLanguageId") {
            if (getLanguageName(x[filterByArray[i].FieldName]) === value) {
              return true;
            }
          } else if (x[filterByArray[i].FieldName] && Array.isArray(x[filterByArray[i].FieldName])) {
            if (x[filterByArray[i].FieldName].some((item: any) => item.Name === value)) {
              return true;
            }
          } else {
            if (x[filterByArray[i].FieldName] === value) {
              return true;
            }
          }
          console.log("filterByArray", filterByArray);
        }
      }
    }
  }); 
 
  console.log("dataList", dataList);
 
  if (dataList.length > 0) {
    setFilterdList(dataList);
  } else {
    setFilterdList([]);
  }*/
}

useEffect(()=>{    
  filterProcess();
},[filterByArray])

  return (
    <>
      <div
        className="table-responsive"
        style={{ height: "calc(100vh - 271px)" }}
      >
        { filterByArray && filterByArray.length > 0 ?
        (<div className="text-right">
              <button className="btn btn-secondary clear" style={{position:"absolute", left:"calc(100% - 250px)", top:"-57px"}} onClick={changeToClearFilter}>Clear Filter
                <FontAwesomeIcon icon={faTimes} /></button>
        </div> ) : ""
        }
        <table
          className="table table-striped text-center table-hover"
          id="tenant_table"
        >
          <thead> 
            <tr
              className="table-header"
              style={{ position: "sticky", top: "-1px" }}
            >
              {/*<th scope="col" style={{ textAlign: "left" }}>
                TenantID
              </th>
              */}
              <th scope="col" style={{ textAlign: "left" }}>
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Name")}
                  >
                    Name
                  </div>
                  <div
                    id="dropdown-menu-Name"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "Name", "Name")
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "Name", "Name")
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "Name", "Name")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" style={{ textAlign: "left" }}>
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Domains")}
                  >
                    Domains
                  </div>
                  <div
                    id="dropdown-menu-Domains"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    {/* <div
                      className="dropdown-item disabled"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "Domains", "Domains")
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item disabled"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "Domains", "Domains")
                      }
                    >
                      Sort Z to A
                    </div> */}
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "Domains", "Domains")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col" style={{ textAlign: "left" }}>
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "SubDomain")}
                  >
                    SubDomain
                  </div>
                  <div
                    id="dropdown-menu-SubDomain"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "SubDomain", "SubDomain")
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "SubDomain", "SubDomain")
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "SubDomain", "SubDomain")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Analytics")}
                  >
                    Analytics
                  </div>
                  <div
                    id="dropdown-menu-Analytics"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "FeatureEnableAnalytics",
                          "Analytics"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "FeatureEnableAnalytics",
                          "Analytics"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "FeatureEnableAnalytics",
                          "Analytics"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown">
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "WorkTime")}
                  >
                    WorkTime
                  </div>
                  <div
                    id="dropdown-menu-WorkTime"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "FeatureEnableWorkTime",
                          "WorkTime"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "FeatureEnableWorkTime",
                          "WorkTime"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "FeatureEnableWorkTime",
                          "WorkTime"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "WorkTimeVersion")}
                  >
                    WorkTime Version
                  </div>
                  <div
                    id="dropdown-menu-WorkTimeVersion"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "WorkTimeVersion",
                          "WorkTimeVersion"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "WorkTimeVersion",
                          "WorkTimeVersion"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "WorkTimeVersion",
                          "WorkTimeVersion"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Planner")}
                  >
                    Planner
                  </div>
                  <div
                    id="dropdown-menu-Planner"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "FeatureEnablePlanner",
                          "Planner"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "FeatureEnablePlanner",
                          "Planner"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "FeatureEnablePlanner", "Planner")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Active")}
                  >
                    Active
                  </div>
                  <div
                    id="dropdown-menu-Active"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "Active", "Active")
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "Active", "Active")
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "Active", "Active")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Trial")}
                  >
                    Trial
                  </div>
                  <div
                    id="dropdown-menu-Trial"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "IsTrial", "Trial")
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "IsTrial", "Trial")
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "IsTrial", "Trial")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "TrialEndDate")}
                  >
                    TrialEndDate
                  </div>
                  <div
                    id="dropdown-menu-TrialEndDate"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "TrialEndDate",
                          "TrialEndDate"
                        )
                      }
                    >
                      Sort Asce
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "TrialEndDate",
                          "TrialEndDate"
                        )
                      }
                    >
                      Sort Desc
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "TrialEndDate", "TrialEndDate")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Meetingroom")}
                  >
                    Meetingroom
                  </div>
                  <div
                    id="dropdown-menu-Meetingroom"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EnableMeetingRoom",
                          "Meetingroom"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EnableMeetingRoom",
                          "Meetingroom"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "EnableMeetingRoom", "Meetingroom")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Deskbooking")}
                  >
                    Deskbooking
                  </div>
                  <div
                    id="dropdown-menu-Deskbooking"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EnableDeskBooking",
                          "Deskbooking"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EnableDeskBooking",
                          "Deskbooking"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "EnableDeskBooking", "Deskbooking")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "Guestregistration")
                    }
                  >
                    Guestregistration
                  </div>
                  <div
                    id="dropdown-menu-Guestregistration"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EnableGuestBookings",
                          "Guestregistration"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EnableGuestBookings",
                          "Guestregistration"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "EnableGuestBookings",
                          "Guestregistration"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "ManagerBooking")}
                  >
                    Manager Booking
                  </div>
                  <div
                    id="dropdown-menu-ManagerBooking"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EnableManagerBooking",
                          "ManagerBooking"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EnableManagerBooking",
                          "ManagerBooking"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "EnableManagerBooking",
                          "ManagerBooking"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Canteen")}
                  >
                    Canteen
                  </div>
                  <div
                    id="dropdown-menu-Canteen"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "EnableCanteen", "Canteen")
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "EnableCanteen", "Canteen")
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "EnableCanteen", "Canteen")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Parking")}
                  >
                    Parking
                  </div>
                  <div
                    id="dropdown-menu-Parking"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "EnableParking", "Parking")
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "EnableParking", "Parking")
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "EnableParking", "Parking")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Sensors")}
                  >
                    Sensors
                  </div>
                  <div
                    id="dropdown-menu-Sensors"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "EnableSensors", "Sensors")
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "EnableSensors", "Sensors")
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "EnableSensors", "Sensors")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "RoomAlyzerID")}
                  >
                    RoomAlyzerID
                  </div>
                  <div
                    id="dropdown-menu-RoomAlyzerID"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "RoomAlyzerId",
                          "RoomAlyzerID"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "RoomAlyzerId",
                          "RoomAlyzerID"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "RoomAlyzerId", "RoomAlyzerID")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              {/* New Fields Added From Here*/}
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "Price")}
                  >
                    Price
                  </div>
                  <div
                    id="dropdown-menu-Price"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Asce", "Price", "Price")
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(event, "Desc", "Price", "Price")
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "Price", "Price")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "AllowSpecifySeat")}
                  >
                    Allow Specify Seat
                  </div>
                  <div
                    id="dropdown-menu-AllowSpecifySeat"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "AllowSpecifySeat",
                          "AllowSpecifySeat"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "AllowSpecifySeat",
                          "AllowSpecifySeat"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "AllowSpecifySeat",
                          "AllowSpecifySeat"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "ColleagueBooking")}
                  >
                    Colleague Booking
                  </div>
                  <div
                    id="dropdown-menu-ColleagueBooking"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EnableColleagueBooking",
                          "ColleagueBooking"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EnableColleagueBooking",
                          "ColleagueBooking"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "EnableColleagueBooking",
                          "ColleagueBooking"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              {/*<th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "GuestregistrationLink")
                    }
                  >
                    GuestregistrationLink
                  </div>
                  <div
                    id="dropdown-menu-GuestregistrationLink"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "ShowGuestregistrationLink",
                          "GuestregistrationLink"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "ShowGuestregistrationLink",
                          "GuestregistrationLink"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "ShowGuestregistrationLink",
                          "GuestregistrationLink"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "InfoscreenLink")}
                  >
                    Infoscreen Link
                  </div>
                  <div
                    id="dropdown-menu-InfoscreenLink"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "ShowInfoscreenLink",
                          "InfoscreenLink"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "ShowInfoscreenLink",
                          "InfoscreenLink"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "ShowInfoscreenLink",
                          "InfoscreenLink"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "AnalyticsLink")}
                  >
                    Analytics Link
                  </div>
                  <div
                    id="dropdown-menu-AnalyticsLink"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "ShowAnalyticsLink",
                          "AnalyticsLink"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "ShowAnalyticsLink",
                          "AnalyticsLink"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "ShowAnalyticsLink",
                          "AnalyticsLink"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "MobileMenuShowQR")}
                  >
                    MobileMenu ShowQR
                  </div>
                  <div
                    id="dropdown-menu-MobileMenuShowQR"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "MobileMenuShowQR",
                          "MobileMenuShowQR"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "MobileMenuShowQR",
                          "MobileMenuShowQR"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "MobileMenuShowQR",
                          "MobileMenuShowQR"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "MobileMenuShowCheckin")
                    }
                  >
                    MobileMenu ShowCheckin
                  </div>
                  <div
                    id="dropdown-menu-MobileMenuShowCheckin"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "MobileMenuShowCheckin",
                          "MobileMenuShowCheckin"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "MobileMenuShowCheckin",
                          "MobileMenuShowCheckin"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "MobileMenuShowCheckin",
                          "MobileMenuShowCheckin"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "AddBookingsInOutlook")
                    }
                  >
                    Add bookings in outlook
                  </div>
                  <div
                    id="dropdown-menu-AddBookingsInOutlook"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "SaveReservationsInExchange",
                          "AddBookingsInOutlook"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "SaveReservationsInExchange",
                          "AddBookingsInOutlook"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "SaveReservationsInExchange",
                          "AddBookingsInOutlook"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "PreRegisterRegisteredGuests")
                    }
                  >
                    Pre Register registered guests
                  </div>
                  <div
                    id="dropdown-menu-PreRegisterRegisteredGuests"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "GuestRegistrationPreRegisterGuestsRegistered",
                          "PreRegisterRegisteredGuests"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "GuestRegistrationPreRegisterGuestsRegistered",
                          "PreRegisterRegisteredGuests"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "GuestRegistrationPreRegisterGuestsRegistered",
                          "PreRegisterRegisteredGuests"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "PreRegisterMeetingGuests")
                    }
                  >
                    PreRegister meeting guests
                  </div>
                  <div
                    id="dropdown-menu-PreRegisterMeetingGuests"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "GuestRegistrationPreRegisterGuestsMeetings",
                          "PreRegisterMeetingGuests"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "GuestRegistrationPreRegisterGuestsMeetings",
                          "PreRegisterMeetingGuests"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "GuestRegistrationPreRegisterGuestsMeetings",
                          "PreRegisterMeetingGuests"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
                    </th> */}
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "DefaultLanguage")}
                  >
                    Default Language
                  </div>
                  <div
                    id="dropdown-menu-DefaultLanguage"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "DefaultLanguageId",
                          "DefaultLanguage"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "DefaultLanguageId",
                          "DefaultLanguage"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "DefaultLanguageId",
                          "DefaultLanguage"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              {/*<th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "EmailSenderAddress")
                    }
                  >
                    Email Sender Address
                  </div>
                  <div
                    id="dropdown-menu-EmailSenderAddress"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EmailSenderAddress",
                          "EmailSenderAddress"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EmailSenderAddress",
                          "EmailSenderAddress"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "EmailSenderAddress",
                          "EmailSenderAddress"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "MaxDaysOfBookingAhead")
                    }
                  >
                    Max Days Of Booking Ahead
                  </div>
                  <div
                    id="dropdown-menu-MaxDaysOfBookingAhead"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "MaxDaysOfBookingAhead",
                          "MaxDaysOfBookingAhead"
                        )
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "MaxDaysOfBookingAhead",
                          "MaxDaysOfBookingAhead"
                        )
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "MaxDaysOfBookingAhead",
                          "MaxDaysOfBookingAhead"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "MaximumBookingsAllowed")
                    }
                  >
                    Maximum Bookings Allowed
                  </div>
                  <div
                    id="dropdown-menu-MaximumBookingsAllowed"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "MaximumBookingsAllowed",
                          "MaximumBookingsAllowed"
                        )
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "MaximumBookingsAllowed",
                          "MaximumBookingsAllowed"
                        )
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "MaximumBookingsAllowed",
                          "MaximumBookingsAllowed"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "WorkHoursStart")}
                  >
                    Work Hours Start
                  </div>
                  <div
                    id="dropdown-menu-WorkHoursStart"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "WorkHoursStart",
                          "WorkHoursStart"
                        )
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "WorkHoursStart",
                          "WorkHoursStart"
                        )
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "WorkHoursStart", "WorkHoursStart")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) => openDropdown(event, "WorkHoursEnd")}
                  >
                    Work Hours End
                  </div>
                  <div
                    id="dropdown-menu-WorkHoursEnd"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "WorkHoursEnd",
                          "WorkHoursEnd"
                        )
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "WorkHoursEnd",
                          "WorkHoursEnd"
                        )
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(event, "WorkHoursEnd", "WorkHoursEnd")
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "ShowAppointmentsFromExchange")
                    }
                  >
                    Show Appointments From Exchange
                  </div>
                  <div
                    id="dropdown-menu-ShowAppointmentsFromExchange"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "ShowAppointmentsFromExchange",
                          "ShowAppointmentsFromExchange"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "ShowAppointmentsFromExchange",
                          "ShowAppointmentsFromExchange"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "ShowAppointmentsFromExchange",
                          "ShowAppointmentsFromExchange"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "AnonymizeAfterDays")
                    }
                  >
                    Anonymize After Days
                  </div>
                  <div
                    id="dropdown-menu-AnonymizeAfterDays"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "AnonymizeAfterDays",
                          "AnonymizeAfterDays"
                        )
                      }
                    >
                      Sort 0 to 9
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "AnonymizeAfterDays",
                          "AnonymizeAfterDays"
                        )
                      }
                    >
                      Sort 9 to 0
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "AnonymizeAfterDays",
                          "AnonymizeAfterDays"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "CheckInAutobookingAutomatically")
                    }
                  >
                    Check in autobooking
                  </div>
                  <div
                    id="dropdown-menu-CheckInAutobookingAutomatically"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "AutobookingAlwaysCheckedIn",
                          "CheckInAutobookingAutomatically"
                        )
                      }
                    >
                      Sort A to Z
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "AutobookingAlwaysCheckedIn",
                          "CheckInAutobookingAutomatically"
                        )
                      }
                    >
                      Sort Z to A
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "AutobookingAlwaysCheckedIn",
                          "CheckInAutobookingAutomatically"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "SendCateringEmailToCostcenter")
                    }
                  >
                    Send catering email
                  </div>
                  <div
                    id="dropdown-menu-SendCateringEmailToCostcenter"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "EmailCateringToCostCenter",
                          "SendCateringEmailToCostcenter"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "EmailCateringToCostCenter",
                          "SendCateringEmailToCostcenter"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "EmailCateringToCostCenter",
                          "SendCateringEmailToCostcenter"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
              </th>
              <th scope="col">
                <div className="dropdown" ref={wrapperRef}>
                  <div
                    className="dropdown-toggle"
                    onClick={(event) =>
                      openDropdown(event, "BookingNotificationToGuest")
                    }
                  >
                    Send booking notification to guest
                  </div>
                  <div
                    id="dropdown-menu-BookingNotificationToGuest"
                    className="dropdown-menu table-sorting-header mt-3"
                    style={{ display: "none" }}
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Asce",
                          "SendReservationNotificationToGuest",
                          "BookingNotificationToGuest"
                        )
                      }
                    >
                      Sort Yes to No
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        handleSortBy(
                          event,
                          "Desc",
                          "SendReservationNotificationToGuest",
                          "BookingNotificationToGuest"
                        )
                      }
                    >
                      Sort No to Yes
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={(event: any) =>
                        openSidePanel(
                          event,
                          "SendReservationNotificationToGuest",
                          "BookingNotificationToGuest"
                        )
                      }
                    >
                      Filter By
                    </div>
                  </div>
                </div>
                    </th>*/}

              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {filterdList
              ? filterdList
                  .slice()
                  .sort((a: any, b: any) => {
                    if (a[FiledName] == null && b[FiledName] == null) return 0;
                    if (order == "Asce") {
                      if (a[FiledName] === null) return -1;
                      if (b[FiledName] === null) return 1;
                    } else {
                      if (a[FiledName] === null) return 1;
                      if (b[FiledName] === null) return -1;
                    }

                    return typeof a[FiledName] != "string"
                      ? typeof a[FiledName] == "number" && FiledName != "DefaultLanguageId"
                        ? order == "Asce"
                          ? a[FiledName] - b[FiledName]
                          : b[FiledName] - a[FiledName]
                        : order == "Asce"
                        ? b[FiledName] - a[FiledName]
                        : a[FiledName] - b[FiledName]
                      : order == "Asce"
                      ? FiledName != "DefaultLanguageId" ? a[FiledName].localeCompare(b[FiledName]) : getLanguageName(a[FiledName]).localeCompare(getLanguageName(b[FiledName]))
                      : FiledName != "DefaultLanguageId" ? b[FiledName].localeCompare(a[FiledName]) : getLanguageName(b[FiledName]).localeCompare(getLanguageName(a[FiledName]));
                  })
                  /*.filter((x: any) => {
                    if (
                      selectedValueToFilter && selectedValueToFilter.length > 0
                    ) {
                      return selectedValueToFilter.some((value: any) =>
                        x[FiledName] && Array.isArray(x[FiledName]) ? x[FiledName].some( (item: any) => item.Name === value)
                         : typeof value == "string" && value.indexOf("-") > -1 && x[FiledName] && value.split("-").length == 3 ? x[FiledName].split("T")[0] == value.split("T")[0]
                         : FiledName == "DefaultLanguageId" ? getLanguageName(x[FiledName]) === value : x[FiledName] === value
                      );
                    } else {
                      return true; // Include all elements if no filter is applied
                    }
                  })*/
                  .map((el: any) => (
                    <tr key={el.Id} onClick={() => onRowLoadEdit(el.Id)}>
                      {/*<td style={{ whiteSpace: "nowrap", textAlign: "left" }}>
                        {el.TenantId}
                      </td>
                      */}
                      <td style={{ textAlign: "left" }}>{el.Name}</td>
                      <td style={{ textAlign: "left" }}>
                        {el.Domains.map((name: any) => (
                          <div key={name.Id}>{name.Name}</div>
                        ))}
                      </td>
                      <td style={{ textAlign: "left" }}>{el.SubDomain}</td>
                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="FeatureEnableAnalytics"
                            defaultChecked={rowData.FeatureEnableAnalytics}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.FeatureEnableAnalytics}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.FeatureEnableAnalytics == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="FeatureEnableWorkTime"
                            defaultChecked={rowData.FeatureEnableWorkTime}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.FeatureEnableWorkTime}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {" "}
                          {el.FeatureEnableWorkTime == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <select
                            name="WorkTimeVersion"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            // style={{ width: "90px" }}
                            defaultValue={rowData.WorkTimeVersion ?? ""}
                          >
                            {options.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : (
                        <td>{el.WorkTimeVersion}</td>
                      )}
                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="FeatureEnablePlanner"
                            defaultChecked={rowData.FeatureEnablePlanner}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.FeatureEnablePlanner}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.FeatureEnablePlanner == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="Active"
                            defaultChecked={rowData.Active}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.Active}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.Active == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="IsTrial"
                            defaultChecked={rowData.IsTrial}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.IsTrial}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.IsTrial == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <DateField
                            date={new Date(rowData.TrialEndDate)}
                            dateInputclass={"form-control"}
                            // style={{ width: "110px" }}
                            onChangeDate={updateStartDate}
                          />
                        </td>
                      ) : (
                        <td>{getTrialEndDate(el.TrialEndDate)}</td>
                        //<td>{el.TrialEndDate}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableMeetingRoom"
                            defaultChecked={rowData.EnableMeetingRoom}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableMeetingRoom}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableMeetingRoom == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableDeskBooking"
                            defaultChecked={rowData.EnableDeskBooking}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableDeskBooking}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableDeskBooking == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableGuestBookings"
                            defaultChecked={rowData.EnableGuestBookings}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableGuestBookings}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableGuestBookings == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableManagerBooking"
                            defaultChecked={rowData.EnableManagerBooking}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableManagerBooking}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableManagerBooking == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableCanteen"
                            defaultChecked={rowData.EnableCanteen}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableCanteen}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableCanteen == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableParking"
                            defaultChecked={rowData.EnableParking}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableParking}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableParking == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableSensors"
                            defaultChecked={rowData.EnableSensors}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableSensors}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableSensors == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="text"
                            name="RoomAlyzerId"
                            className="form-control"
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.RoomAlyzerId ?? ""}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.RoomAlyzerId}</td>
                      )}

                      {/* New Props Added from here */}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="text"
                            name="Price"
                            //defaultChecked={rowData.Price}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.Price}
                            className="form-control"
                            style={{ cursor: "pointer", width: "70px" }}
                          />
                        </td>
                      ) : (
                        <td>{el.Price}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="AllowSpecifySeat"
                            defaultChecked={rowData.AllowSpecifySeat}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.AllowSpecifySeat}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.AllowSpecifySeat == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EnableColleagueBooking"
                            defaultChecked={rowData.EnableColleagueBooking}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EnableColleagueBooking}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EnableColleagueBooking == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {/*{isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="ShowGuestregistrationLink"
                            defaultChecked={rowData.ShowGuestregistrationLink}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.ShowGuestregistrationLink}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.ShowGuestregistrationLink == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="ShowInfoscreenLink"
                            defaultChecked={rowData.ShowInfoscreenLink}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.ShowInfoscreenLink}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.ShowInfoscreenLink == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="ShowAnalyticsLink"
                            defaultChecked={rowData.ShowAnalyticsLink}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.ShowAnalyticsLink}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.ShowAnalyticsLink == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="MobileMenuShowQR"
                            defaultChecked={rowData.MobileMenuShowQR}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.MobileMenuShowQR}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.MobileMenuShowQR == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="MobileMenuShowCheckin"
                            defaultChecked={rowData.MobileMenuShowCheckin}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.MobileMenuShowCheckin}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.MobileMenuShowCheckin == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="SaveReservationsInExchange"
                            defaultChecked={rowData.SaveReservationsInExchange}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.SaveReservationsInExchange}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.SaveReservationsInExchange == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="GuestRegistrationPreRegisterGuestsRegistered"
                            defaultChecked={
                              rowData.GuestRegistrationPreRegisterGuestsRegistered
                            }
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.GuestRegistrationPreRegisterGuestsRegistered}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.GuestRegistrationPreRegisterGuestsRegistered ==
                          true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="GuestRegistrationPreRegisterGuestsMeetings"
                            defaultChecked={
                              rowData.GuestRegistrationPreRegisterGuestsMeetings
                            }
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.GuestRegistrationPreRegisterGuestsMeetings}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.GuestRegistrationPreRegisterGuestsMeetings ==
                          true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                          */}

                      {isRowEdit == true && isRowEditId == el.Id ? ( //remaining for default language
                        <td>
                          <select
                            name="DefaultLanguageId"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            value={rowData.DefaultLanguageId}
                          >
                            {languageList.map((item: any, index: number) => (
                              <option key={`option${index}`} value={item.Id}>
                                {item.Displayname}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : (
                        <td style={{ textAlign: "left" }}>
                          <span className="mr-1">
                            <img
                              width={"20px"}
                              height={"20px"}
                              src={getLanguageIcon(el.DefaultLanguageId)}
                            ></img>
                          </span>
                          {getLanguageName(el.DefaultLanguageId)}
                        </td>
                      )}

                      {/*}{isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="text"
                            name="EmailSenderAddress"
                            //defaultChecked={rowData.EmailSenderAddress}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.EmailSenderAddress}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.EmailSenderAddress}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="number"
                            name="MaxDaysOfBookingAhead"
                            //defaultChecked={rowData.MaxDaysOfBookingAhead}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.MaxDaysOfBookingAhead}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.MaxDaysOfBookingAhead}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="number"
                            name="MaximumBookingsAllowed"
                            //defaultChecked={rowData.MaximumBookingsAllowed}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.MaximumBookingsAllowed}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.MaximumBookingsAllowed}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="number"
                            name="WorkHoursStart"
                            //defaultChecked={rowData.WorkHoursStart}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.WorkHoursStart}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.WorkHoursStart}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="number"
                            name="WorkHoursEnd"
                            //defaultChecked={rowData.WorkHoursEnd}
                            onChange={(event) => handleFieldChange(event)}
                            value={rowData.WorkHoursEnd}
                            className="form-control"
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>{el.WorkHoursEnd}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? ( //For show appointment from exchange
                        <td>
                          <select
                            name="ShowAppointmentsFromExchange"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            // style={{ width: "90px" }}
                            defaultValue={
                              rowData.ShowAppointmentsFromExchange ?? ""
                            }
                          >
                            {showAppointmentExchangeList.map(
                              (item: any, index: number) => (
                                <option
                                  key={`option_${index}}`}
                                  value={item.value}
                                >
                                  {item.caption}
                                </option>
                              )
                            )}
                          </select>
                        </td>
                      ) : (
                        <td>{el.ShowAppointmentsFromExchange}</td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? ( //For Anonymize
                        <td>
                          <select
                            name="AnonymizeAfterDays"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            // style={{ width: "90px" }}
                            defaultValue={rowData.AnonymizeAfterDays ?? ""}
                          >
                            {showAnonymizeAfterDays.map(
                              (item: any, index: number) => (
                                <option
                                  key={`option${index}}`}
                                  value={item.value}
                                >
                                  {item.caption}
                                </option>
                              )
                            )}
                          </select>
                        </td>
                      ) : (
                        <td>
                          {el.AnonymizeAfterDays
                            ? el.AnonymizeAfterDays
                            : el.AnonymizeAfterDays}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? ( // for Check in autobooking automatically
                        <td>
                          <select
                            name="AutobookingAlwaysCheckedIn"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            // style={{ width: "90px" }}
                            defaultValue={
                              rowData.AutobookingAlwaysCheckedIn.toString() ??
                              ""
                            }
                          >
                            {checkinAutobookingList.map((option) => (
                              <option
                                key={option.value.toString()}
                                value={option.value.toString()}
                              >
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </td>
                      ) : (
                        <td>
                          {el.AutobookingAlwaysCheckedIn.toString() &&
                          el.AutobookingAlwaysCheckedIn.toString() == "false"
                            ? "Inherit from location"
                            : "Yes"}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="EmailCateringToCostCenter"
                            defaultChecked={rowData.EmailCateringToCostCenter}
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.EmailCateringToCostCenter}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.EmailCateringToCostCenter == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}

                      {isRowEdit == true && isRowEditId == el.Id ? (
                        <td>
                          <input
                            type="checkbox"
                            name="SendReservationNotificationToGuest"
                            defaultChecked={
                              rowData.SendReservationNotificationToGuest
                            }
                            onChange={(event) => handleFieldChange(event)}
                            //value={rowData.SendReservationNotificationToGuest}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                      ) : (
                        <td>
                          {el.SendReservationNotificationToGuest == true ? (
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faCheckCircle}
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      )}
                          */}

                      <td>
                        {isRowEdit == true && isRowEditId == el.Id ? (
                          <span className="d-flex">
                            <FontAwesomeIcon
                              className="icon-color"
                              icon={faSave}
                              onClick={(event) => EditTenant(event)}
                            />
                            <FontAwesomeIcon
                              className="icon-color ml-3"
                              onClick={() => cancelEdit(el.Id, el)}
                              icon={faCancel}
                            />
                          </span>
                        ) : (
                          <FontAwesomeIcon
                            className="icon-color"
                            icon={faEdit}
                            onClick={() => props?.onEditClick(el.Id)}
                          />
                        )}
                      </td>
                    </tr>
                  ))
              : ""}
          </tbody>
        </table>
      </div>
      {IsEmployeeViewDetails && (
        <div>
          <>
            <div id="infobox1" className="infobox bg-white">
              <div className="float-right redclose p-3" id="close">
                <FontAwesomeIcon
                  icon={faClose}
                  onClick={() => closeSidePanel()}
                />
              </div>
              <div className="d-flex justify-content-between align-items-center mt-3 px-3 mb-1">
                <div className="col-md-12 pr-0">
                  <h5 className="mt-1 break-all">Filter by</h5>
                  <p className="mb-0">{headerForFilterBy.replace(/([A-Z])/g, " $1").trim()}</p>
                </div>
              </div>

              <div className="p-3">
                <div className="col-md-12 col-sm-12">
                  <input
                    type="search"
                    className="form-control mb-3"
                    placeholder="Search"
                    value={query}
                    onChange={(event: any) => handleFilterbySearch(event)}
                  />
                </div>

                {getFilteredData && getFilteredData.length > 0 ? (
                  getFilteredData
                    .slice()
                    .sort((a: any, b: any) => {
                      if (typeof a === "number" && typeof b === "number") {
                        return a - b;
                      } else if (
                        typeof a === "string" &&
                        typeof b === "string"
                      ) {
                        return a.localeCompare(b);
                      } else if (
                        typeof a === "boolean" &&
                        typeof b === "boolean"
                      ) {
                        return a === b ? 0 : a ? 1 : -1;
                      }
                    })
                    .map((item: any, index: number) => (
                      <div className="form-group row px-3 mb-0" key={index}>
                        <div className="col-sm-12 d-flex align-items-center filter-sidebar mb-2">
                          {!Array.isArray(item) ? (
                            <input
                              type="checkbox"
                              name="FilterBySearch"
                              defaultChecked={getDefaultChecked(item)}
                              onChange={(event) =>
                                handleFilterBySelected(event)
                              }
                              value={item}
                              style={{ cursor: "pointer" }}
                            />
                          ) : (
                            ""
                          )}
                          <label className="col-form-label pb-0 ml-2">
                            {Array.isArray(item)
                              ? item.map((x: any, i: number) => (
                                  <div className="d-flex align-items-center mb-2" key={i}>
                                    <input
                                      type="checkbox"
                                      name="FilterBySearch"
                                      defaultChecked={getDefaultChecked(item)}
                                      onChange={(event) =>
                                        handleFilterBySelected(event)
                                      }
                                      value={x.Name}
                                      style={{ cursor: "pointer" }}
                                    />
                                    <label className="ml-2 mb-0">{x.Name}</label>
                                  </div>
                                ))
                              : typeof item === "boolean"
                              ? item
                                ? "Yes"
                                : "No"
                              : typeof item === "string" && !isNaN(new Date(item).getDate())
                              ? getTrialEndDate(item)
                              : item } 

                          </label>
                        </div>
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>
              <div className=" row">
                <span className="pr-4 ml-5">
                  <button
                    className="btn-primary"
                    style={{ padding: "6px 10px" }}
                    onClick={(event: any) => handleApplyFilter(event)}
                  >
                    Apply Filter
                  </button>
                </span>
                <span>
                  <button
                    className="btn btn-secondary"
                    style={{ padding: "5px 10px" }}
                    onClick={(event: any) => handleClearFilter(event)}
                  >
                    Clear & Close
                  </button>
                </span>
              </div>
            </div>
          </>
        </div>
      )}

      {/* <TableFooter range={range} slice={slice} setPage={setPage} page={page} /> */}
    </>
  );
};

export default TableGrid;
