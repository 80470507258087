import axios from 'axios';
import { appConstant } from '../constants/app-constant';

let token = localStorage.getItem("token");
let graphToken = localStorage.getItem("graphtoken");
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    return axios.create({
        baseURL:appConstant.apiBaseURL,
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' }
    })
}
export const Gapi= () => {
    return axios.create({
        baseURL:appConstant.apiBaseURL,
        headers: { 'Authorization': `Bearer ${graphToken}`, 'Content-Type': 'application/json' }
    })
}

