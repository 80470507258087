import React from "react";
import "./header.css";
import UserDropdown from "./user-dropdown";
import AppLogo from "./app-logo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg primary_bg" >
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        {/* <span className="faBars"></span> */}
        <FontAwesomeIcon icon={faBars} />
      </button>
      <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 leftPanel text-center d-flex align-items-center">
        <AppLogo></AppLogo>
        <h4 className="Dashboard-heading">Tenant</h4>
      </div>
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12">
       
        <UserDropdown></UserDropdown>
      </div>
    </nav>
  );
}

export default Header;
