import DateRangePicker from "react-bootstrap-daterangepicker";
import React, { useEffect, useId } from "react";

function DateField(props: any) {
  const Id = useId();
  const dateRangeRef = React.useRef<DateRangePicker>(null);
  const [date, setDate] = React.useState(props?.date);

  useEffect(() => {
    setDate(props?.date);
    dateRangeRef.current?.setStartDate(props?.date);
    dateRangeRef.current?.setEndDate(props?.date);
  }, [props?.date]);

  const handleDateChange = (event: any) => {const newStartDate = event.target.value;
    setDate(newStartDate);
    props?.onChangeDate(newStartDate);
  };
  return (
    <>
      {props?.labelName ? (
        <label className={props?.labelClass}>{props?.labelName}</label>
      ) : (
        ""
      )}
      <div className={props?.dateContainerClass}>
        <DateRangePicker
          ref={dateRangeRef}
          initialSettings={{
            showDropdowns: true,
            singleDatePicker: true,
            startDate: date,
            autoApply: true,
            locale: {
              format: 'DD-MM-YYYY',
              //firstDay: 1,
          }
          }}
          onCallback={(start) => {
            var offset = new Date(start._d).getTimezoneOffset();
            const newStartDate = new Date(start._d).setMinutes(-(offset));     // For managing offset value otherwise it will take one day back.
            setDate(newStartDate);
            props?.onChangeDate(newStartDate);
            dateRangeRef.current?.setStartDate(newStartDate);
            dateRangeRef.current?.setEndDate(newStartDate);
          }}
        >
          <input
            type="text"
            className={props?.dateInputclass}
            id={"date_" + Id}
            style={{ width: "110px" }}
            onChange={handleDateChange}
          />
        </DateRangePicker>
      </div>
    </>
  );
}

export default DateField;
