/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import logo from "../images/mydesk-logo.png";

function AppLogo() {
  return (
    <a className="navbar-brand d-flex align-items-center" href="/">
      <img src={logo}
        id="AppLogoImage"
        style={{ height: 59 + " !important" }}
        
      />
      <span className="divided"></span>
    </a>
  );
}

export default AppLogo;
