import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import AddForm from '../components/custom/add-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

function AddTenant() {
  const location = useLocation()
  let indexdArray1 = "";
  if(location.state.data) {
    indexdArray1 = location?.state?.data;
  }
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="container-fluid content-container mt-5">
        <div className="row title-top border-bottom d-flex align-items-center">
          <div className="col-6">
            <h3 className='text-uppercase'>Add Tenant</h3>
          </div>
          <div className="col-6">
            <div className="text-right">
              <button onClick={goBack} className="btn btn-secondary">
                <FontAwesomeIcon icon={faAngleDoubleLeft} />
                <span className="ms-2">&nbsp;Back</span>
              </button>
            </div>
          </div>
        </div>
        <div className="row title-top">
          <div className="col-12">
            <AddForm onCloseClick={goBack} adddata={indexdArray1} />
          </div>
        </div>
      </div>
    </>
  )
}

export default AddTenant