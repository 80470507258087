import { faImage, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import DateField from "../general/date-field";
import mainService from "../../service/main-service";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function EditForm(props: any) {
  const showAppointmentExchangeList = [
    { value: "MyDeskOnly", caption: "MyDesk meetings only" },
    { value: "MyDeskAndTeams", caption: "MyDesk and Teams meetings" },
    { value: "All", caption: "All appointments" },
  ];

  const showAnonymizeAfterDays = [
    { value: 0, caption: "Never" },
    { value: 14, caption: "After 14 days" },
    { value: 30, caption: "1 Month" },
    { value: 90, caption: "3 Months" },
    { value: 180, caption: "6 Months" },
    { value: 365, caption: "1 Year" },
  ];

  const regexpattern = new RegExp(
    "^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{2,6}$"
  );

  const emailRegexpattern = new RegExp(
    "[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[.]{1}[a-zA-Z]{2,}$"
  );
  const passwordRegexpattern = new RegExp(
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})"
  );

  const [emailSenderAddress, setEmailsender] = useState(
    props?.editObject?.EmailSenderAddress
  );
  const [subDomain, setSubDomain] = useState(props?.editObject?.SubDomain);
  const [password, setPassword] = useState(props?.editObject?.Password);

  const ParamOutOfOfficeTypes: any[] = props?.editObject?.OutOfOfficeTypes;
  const [OutOfOfficeTypes, setOutOfOfficeTypes] = useState(
    ParamOutOfOfficeTypes
  );
  //console.log("OutOfOfficeTypes", OutOfOfficeTypes)

  function handelEmailsenderonblur(event: any) {
    if (!emailRegexpattern.test(emailSenderAddress)) {
      Swal.fire({ text: "Please insert valid Email Address" });
      setEmailsender("");
    }
  }

  function handelEmailsenderClick(event: any) {
    setEmailsender(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      EmailSenderAddress: event.target.value,
    });
  }

  function handleSubDomainClick(event: any) {
    setSubDomain(event.target.value);
    setTempDefaultData({ ...tempDefaultdata, SubDomain: event.target.value });
  }

  let checkinAutobookingList = [
    { name: 'Yes', value: true },
    { name: 'Inherit from location', value: false },
  ];

  const [autobookingAlwaysCheckedIn,setAutobookingAlwaysCheckedIn] = useState(props?.editObject?.AutobookingAlwaysCheckedIn)
  
  const handleAutobookingChange = (event: any) => {
    //console.log("event Target", event.target.value);
    const booleanValue = event.target.value == 'true';
    setAutobookingAlwaysCheckedIn(booleanValue );
    setTempDefaultData({ ...tempDefaultdata, AutobookingAlwaysCheckedIn: booleanValue });
  };


  //   function handelsubdominonblur(){
  //     if(!regexpattern.test(subDomain)){

  //       Swal.fire({text:'Please insert valid domain name'})
  //       setSubDomain("");

  //     }
  // }

  /*function handlePasswordClick(event: any) {
  setPassword(event.target.value);
  setTempDefaultData({ ...tempDefaultdata, "Password": event.target.value });
}


function handelPasswordonblur(){
  if(!passwordRegexpattern.test(password)){
    Swal.fire({text:'Password must be eight characters including one uppercase letter, one special character and alphanumeric characters'})
    setPassword("");
  }
}*/
  const ParamDomain: any[] = props?.editObject?.Domains;
  var domainsList = [];
  if (ParamDomain.length > 0) {
    for (let i = 0; i < ParamDomain.length; i++) {
      domainsList.push(ParamDomain[i].Name);
    }
  }
  const initialDomain: any[] = domainsList;
  
  const [domains1, setDomains1] = useState(initialDomain);

  const [trialEndDate, setTrialEndDate] = useState(
    new Date(props?.editObject?.TrialEndDate)
  );
  const updateStartDate = (dt: string) => {
    setTrialEndDate(new Date(dt));
    setTempDefaultData({
      ...tempDefaultdata,
      TrialEndDate: new Date(dt).toDateString(),
    });
  };

  const[languageDefaultId, setlanguageDefaultId ] = useState(props?.editObject?.DefaultLanguageId);
  const[workTimeVersion, setWorkTimeVersion] = useState(props?.editObject?.WorkTimeVersion);

  let obj: any = {
    TenantId: props?.editObject?.TenantId,
    Id: props?.editObject?.Id,
    Name: props?.editObject?.Name,
    SubDomain: subDomain,
    Active: props?.editObject?.Active,
    IsTrial: props?.editObject?.IsTrial,
    TrialEndDate: trialEndDate,
    Price: props?.editObject?.Price,
    EnableColleagueBooking: props?.editObject?.EnableColleagueBooking,
    EnableManagerBooking: props?.editObject?.EnableManagerBooking,
    EnableMeetingRoom: props?.editObject?.EnableMeetingRoom,
    AllowSpecifySeat: props?.editObject?.AllowSpecifySeat,
    MaxDaysOfBookingAhead: props?.editObject?.MaxDaysOfBookingAhead,
    EmailSenderAddress: emailSenderAddress,
    Domains: domains1,
    MobileMenuShowQR: props?.editObject?.MobileMenuShowQR,
    MobileMenuShowCheckin: props?.editObject?.MobileMenuShowCheckin,
    AnonymizeAfterDays: props?.editObject?.AnonymizeAfterDays,
    WorkHoursStart: props?.editObject?.WorkHoursStart,
    WorkHoursEnd: props?.editObject?.WorkHoursEnd,
    SaveReservationsInExchange: props?.editObject?.SaveReservationsInExchange,
    ShowAppointmentsFromExchange: props?.editObject?.ShowAppointmentsFromExchange,
    AllowAutoSelectSameSeat: props?.editObject?.AllowAutoSelectSameSeat,
    MaximumBookingsAllowed: props?.editObject?.MaximumBookingsAllowed,
    EnableCanteen: props?.editObject?.EnableCanteen,
    EnableDeskBooking: props?.editObject?.EnableDeskBooking,
    EnableParking: props?.editObject?.EnableParking,
    EnableGuestBookings: props?.editObject?.EnableGuestBookings,
    UserName: props?.editObject?.UserName,
    Password: password,
    EnableSensors: props?.editObject?.EnableSensors,
    DisclaimerText: props?.editObject?.DisclaimerText,

    ShowGuestregistrationLink: props?.editObject?.ShowGuestregistrationLink,
    ShowInfoscreenLink: props?.editObject?.ShowInfoscreenLink,
    ShowAnalyticsLink: props?.editObject?.ShowAnalyticsLink,
    ShowPlannerLink: props?.editObject?.ShowPlannerLink,
    OutOfOfficeTypes: OutOfOfficeTypes,

    AutobookingAlwaysCheckedIn: autobookingAlwaysCheckedIn,
    EmailCateringToCostCenter: props?.editObject?.EmailCateringToCostCenter,
    SendReservationNotificationToGuest: props?.editObject?.SendReservationNotificationToGuest,
    GuestRegistrationPreRegisterGuestsRegistered: props?.editObject?.GuestRegistrationPreRegisterGuestsRegistered,
    GuestRegistrationPreRegisterGuestsMeetings: props?.editObject?.GuestRegistrationPreRegisterGuestsMeetings,

    WorkTimeVersion:  workTimeVersion,
    RoomAlyzerId: props?.editObject?.RoomAlyzerId,
    DefaultLanguageId: languageDefaultId,
    FeatureEnableWorkTime: props?.editObject?.FeatureEnableWorkTime,
    FeatureEnableGuestRegistration: props?.editObject?.FeatureEnableGuestRegistration,
    FeatureEnablePlanner: props?.editObject?.FeatureEnablePlanner,
    FeatureEnableAnalytics: props?.editObject?.FeatureEnableAnalytics,




  };

  //console.log("tempOfficeData",OutOfOfficeTypes)
  const [tempDefaultdata, setTempDefaultData] = useState(obj);

  function onWorkTimeVersionChange(event : any){
    setWorkTimeVersion(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      WorkTimeVersion: event.target.value,
    });
  }
  const [domain1, setDomain1] = useState("");

  function handleDomainClick(event: any) {
    setDomain1(event.target.value);
  }
  function AddDomains(event: any) {
    if (domain1 === null || domain1 === "") {
      Swal.fire({ text: "Please insert domain name" });
    } else if (!regexpattern.test(domain1)) {
      Swal.fire({ text: "Please insert valid domain name" });
    } else {
      domains1.push(domain1);
      setTempDefaultData({ ...tempDefaultdata, Domains: domains1 });
    }
    setDomain1("");
  }
  function Remove(index: number) {
    var array = [...domains1];
    Swal.fire({
      title: "Are you sure want to delete this domain?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((result) => {
      if (result.isConfirmed) {
        if (index !== -1) {
          array.splice(index, 1);
          setDomains1(array);
        }
      }
    });
    setTempDefaultData({ ...tempDefaultdata, Domains: array });
  }
  const handleFieldChange = (event: any) => {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    let fieldName = event.target.name;

    setTempDefaultData({ ...tempDefaultdata, [fieldName]: value });
  };
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length: number) {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  function EditTenant() {
    //console.log("tempDefaultdata",tempDefaultdata)
    mainService.editTenants(tempDefaultdata);
    Swal.fire({
      position: "center",
      icon: "success",
      title: "Record has been Updated successfully",
      showConfirmButton: false,
      timer: 1500,
    });
    goBack();
  }

  const navigate = useNavigate();
  const goBack = () => {
    navigate("/", { state: { needRefresh: generateString(6) } });
  };

  //////////////////////// OutOfOffice start //////////////////

  //const [OutofofficeLists, setOutofofficeLists] = useState<any[]>([]);
  const [OutofofficeDropDownList, setOutofofficeDropDownList] = useState<any[]>(
    []
  );

  function LoadOutOfOfficeData(data: any[]) {
    const mappedOutOfOfficeList = data.map((d: any) => ({
      Name: d,
    }));
    setOutofofficeDropDownList(mappedOutOfOfficeList);
  }
  function onLanguageChange (event: any){
    setlanguageDefaultId(event.target.value);
    setTempDefaultData({
      ...tempDefaultdata,
      DefaultLanguageId: event.target.value,
    });
   }

   const[languageList, setLanguageList] = useState ([]);
   async function getLanguageList(){
     let languageData: [] = await mainService.getLanguageList();
     setLanguageList(languageData);
    }

  useEffect(() => {
    async function getOutOfOffice() {
      let data: any = await mainService.getOutOfOfficeList();
      LoadOutOfOfficeData(data);
    }
    getOutOfOffice();
    getLanguageList();
  }, []);

  function handleOutOfOfficeList(event: any, proIndex: number) {
    const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
    //console.log("value", value)
    let fieldName = event.target.name;
    let fieldvalue = value;
    var tempOutOfficeList = [...OutOfOfficeTypes];
    tempOutOfficeList[proIndex][fieldName] = fieldvalue;
    setOutOfOfficeTypes(tempOutOfficeList);
    //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": tempOutOfficeList });
    setTempDefaultData({
      ...tempDefaultdata,
      OutOfOfficeTypes: tempOutOfficeList,
    });
  }
  const handleImageClick = (proIndex: number) => {
    let inputFile = document.getElementById(`icon_file_+ ${proIndex}`);
    if (inputFile) inputFile.click();
  };

  const handleFileSelect = (event: any, proIndex: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: any) => {
        const imageURL = event.target?.result as string;
        var tempOutOfficeList = [...OutOfOfficeTypes];
        tempOutOfficeList[proIndex].Icon = imageURL;
        setOutOfOfficeTypes(tempOutOfficeList);
        //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": tempOutOfficeList });
        setTempDefaultData({
          ...tempDefaultdata,
          OutOfOfficeTypes: tempOutOfficeList,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  function InsertRow() {
    setOutOfOfficeTypes((OutOfOfficeTypes) => [
      ...OutOfOfficeTypes,
      { Id: 0, Text: "", ExchangeShowAs: "", Icon: "" || null, IsAbsence: false },
    ]);
  }
  function DeleteRow(event: any, index: number) {
    var temp = [...OutOfOfficeTypes];
    if (index !== -1) {
      temp.splice(index, 1);
      setOutOfOfficeTypes(temp);
      //setTempDefaultData({ ...tempDefaultdata, "OutOfOfficeTypes": temp });
      setTempDefaultData({ ...tempDefaultdata, OutOfOfficeTypes: temp });
    }
    if (OutOfOfficeTypes.length == 0) {
      InsertRow();
    }
  }
  let IsCall = false;

 // For default language

  useEffect(() => {
    //setOutOfOfficeTypes([]);
    if (props?.editObject?.OutOfOfficeTypes.length == 0 && IsCall == false ) {
      InsertRow();
      IsCall = true;
      //console.log("Tejhdb22")
     }
   }, []);

  return (
    <>
      <div className="row">
        <div className="col-md-6 col-sm-12">
          <div className="col-12 p-0 mb-4 mt-3">
            <h5>General Settings</h5>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Tenant ID</label>
            <div className="col-sm-12">
              <input
                type="text"
                name="TenantId"
                onChange={(event) => handleFieldChange(event)}
                value={tempDefaultdata.TenantId}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 col-form-label"> Add Domains</label>
            <div className="col-sm-12">
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  value={domain1}
                  name="domain1"
                  onChange={(event) => handleDomainClick(event)}
                  aria-label="domain's name"
                  aria-describedby="basic-addon2"
                />
                <span
                  className="input-group-text"
                  id="basic-addon2"
                  onClick={(event) => AddDomains(event)}
                >
                  Add
                </span>
              </div>

              {domains1.length > 0 ? (
                <div className="col-sm-12 border rounded mb-4 w-100">
                  <div className="row m-0">
                    {domains1.map((dom, index) => (
                      <div className="col-md-6 p-2 row" key={`domain_${index}`}>
                        <div className="col-md-8">{dom}</div>
                        <div className="col-md-4">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() => {
                              Remove(index);
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {emailSenderAddress ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Email Sender Address
            </label>
            <div className="col-sm-12">
              <input
                type="email"
                onBlur={handelEmailsenderonblur}
                name="EmailSenderAddress"
                onChange={(event) => handelEmailsenderClick(event)}
                className="form-control"
                value={emailSenderAddress}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">Default Language</label>
            <div className="col-sm-12">
              <select
                name="LanguageList"
                onChange={(event) => onLanguageChange(event)}
                className="form-control"
                // defaultValue={"All"}
                value={tempDefaultdata.DefaultLanguageId}
              >
                {languageList.map((item: any, index: number) => (
                  <option key={`option${index}`} value={item.Id}>
                    {item.Displayname}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.MaxDaysOfBookingAhead <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Max Days Of Booking Ahead
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="MaxDaysOfBookingAhead"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.MaxDaysOfBookingAhead}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.MaximumBookingsAllowed <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Maximum Bookings Allowed
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="MaximumBookingsAllowed"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.MaximumBookingsAllowed}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.WorkHoursStart <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Work Hours Start
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="WorkHoursStart"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.WorkHoursStart}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              {tempDefaultdata.WorkHoursEnd <= 0 ? (
                <span className="text-danger">* </span>
              ) : (
                ""
              )}
              Work Hours End
            </label>
            <div className="col-sm-12">
              <input
                type="number"
                name="WorkHoursEnd"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.WorkHoursEnd}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="col-sm-12 col-form-label">DisclaimerText</label>
            <div className="col-sm-12">
              <input
                type="text"
                name="DisclaimerText"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.DisclaimerText ?? ""}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {!tempDefaultdata.ShowAppointmentsFromExchange ? (
                    <span className="text-danger">* </span>
                  ) : (
                    ""
                  )}
                  Show Appointments From Exchange
                </label>

                <div className="col-sm-12">
                  <select
                    name="ShowAppointmentsFromExchange"
                    onChange={(event) => handleFieldChange(event)}
                    className="form-control"
                    value={tempDefaultdata.ShowAppointmentsFromExchange}
                  >
                    {showAppointmentExchangeList.map(
                      (item: any, index: number) => (
                        <option key={`option_${index}}`} value={item.value}>
                          {item.caption}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  {tempDefaultdata.AnonymizeAfterDays <= 0 ? (
                    <span className="text-danger">* </span>
                  ) : (
                    ""
                  )}
                  Anonymize After Days
                </label>
                <div className="col-sm-12">
                  <select
                    name="AnonymizeAfterDays"
                    onChange={(event) => handleFieldChange(event)}
                    className="form-control"
                    value={tempDefaultdata.AnonymizeAfterDays}
                  >
                    {showAnonymizeAfterDays.map((item: any, index: number) => (
                      <option key={`option${index}}`} value={item.value}>
                        {item.caption}
                      </option>
                    ))}
                  </select>
                  {/* <input
                type="number"
                name="AnonymizeAfterDays"
                onChange={(event) => handleFieldChange(event)}
                className="form-control"
                value={tempDefaultdata.AnonymizeAfterDays}
              /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-12 col-form-label">
              Check in autobooking automatically
            </label>
            <div className="col-sm-12">
              <select
                className="form-control"
                value={tempDefaultdata.AutobookingAlwaysCheckedIn.toString()}
                onChange={handleAutobookingChange}
              >
                {checkinAutobookingList.map((option) => (
                  <option
                    key={option.value.toString()}
                    value={option.value.toString()}
                  >
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Send catering email to cost center
                </label>
                <div className="col-sm-12">
                  <input
                    defaultChecked={tempDefaultdata.EmailCateringToCostCenter}
                    value={tempDefaultdata.EmailCateringToCostCenter}
                    type="checkbox"
                    name="EmailCateringToCostCenter"
                    onChange={(event) => handleFieldChange(event)}
                    className="chekbox"
                  />
                </div>
              </div>
            </div>

            <div className="col-md-6 col-sm-12">
              <div className="form-group row">
                <label className="col-sm-12 col-form-label">
                  Send booking notification to guest
                </label>
                <div className="col-sm-12">
                  <input
                    defaultChecked={
                      tempDefaultdata.SendReservationNotificationToGuest
                    }
                    value={tempDefaultdata.SendReservationNotificationToGuest}
                    type="checkbox"
                    name="SendReservationNotificationToGuest"
                    onChange={(event) => handleFieldChange(event)}
                    className="chekbox"
                  />
                </div>
              </div>
            </div>
          </div>

          <div>
            {OutOfOfficeTypes.length > 0
              ? OutOfOfficeTypes?.map((proItem: any, proIndex: number) => (
                  <div className="row " key={`options${proIndex}`}>
                    <div className="col-md-4 col-sm-12">
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          Choose exch. type
                        </label>

                        <div className="col-sm-12">
                          <select
                            name="ExchangeShowAs"
                            onChange={(event) =>
                              handleOutOfOfficeList(event, proIndex)
                            }
                            className="form-control"
                            value={proItem.ExchangeShowAs}
                          >
                            (<option value="">--Select Exch. Type --</option>)
                            {OutofofficeDropDownList.length > 0
                              ? OutofofficeDropDownList.map(
                                  (item: any, index: number) => (
                                    <option
                                      key={`option${index}`}
                                      value={item.Name}
                                    >
                                      {item.Name}
                                    </option>
                                  )
                                )
                              : ""}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className=" col-sm-3">
                      <div className="form-group">
                        <label className="col-sm-12 col-form-label">
                          MyDesk text
                        </label>
                        <div className="col-sm-12">
                          <input
                            type="text"
                            name="Text"
                            onChange={(event) =>
                              handleOutOfOfficeList(event, proIndex)
                            }
                            className="form-control"
                            value={proItem.Text}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="form-group">
                        <label className="col-sm-12 col-form-label">
                          Is Absence
                        </label>
                        <div className="col-sm-2">
                          <input
                            type="checkbox"
                            defaultChecked={proItem.IsAbsence}
                            name="IsAbsence"
                            onChange={(event) =>
                              handleOutOfOfficeList(event, proIndex)
                            }
                            className="form-check"
                            value={proItem.IsAbsence}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-2">
                      <div className="form-group">
                        <label className="col-sm-12 col-form-label pl-0">
                          Icon
                        </label>
                        <div className="align-icon">
                          {proItem?.Icon ? (
                            <img
                              id={`iconImage_${proIndex}`}
                              src={proItem.Icon}
                              onClick={() => handleImageClick(proIndex)}
                              style={{ maxWidth: "100px", height: "40px" }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faImage}
                              onClick={() => handleImageClick(proIndex)}
                              style={{ cursor: "pointer", fontSize: "40px" }}
                            />
                          )}
                          <input
                            type="file"
                            id={`icon_file_+ ${proIndex}`}
                            name="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={(event) =>
                              handleFileSelect(event, proIndex)
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-1">
                      <div className="form-group">
                        <label className="col-sm-12 col-form-label pl-0">
                          Delete
                        </label>
                        <div className="align-icon">
                          <button
                            className="btn btn-sm plusminus-btn"
                            onClick={(event) => DeleteRow(event, proIndex)}
                          >
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
            <button
              className="btn btn-sm plusminus-btn mb-3"
              onClick={InsertRow}
            >
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>

        <div className="col-md-6 col-sm-12">
          <div className="col-12 p-0 mb-4 mt-3">
            <h5>Tenant Settings</h5>
          </div>
          <div className="container-fluid content-container">
            <div className="mt-2">
              <div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        {!subDomain ? (
                          <span className="text-danger">* </span>
                        ) : (
                          ""
                        )}
                        SubDomain
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="SubDomain"
                          onChange={(event) => handleSubDomainClick(event)}
                          className="form-control"
                          value={subDomain}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        {!tempDefaultdata.Name ? (
                          <span className="text-danger">* </span>
                        ) : (
                          ""
                        )}
                        Name
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="Name"
                          onChange={(event) => handleFieldChange(event)}
                          className="form-control"
                          value={tempDefaultdata.Name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Price</label>
                      <div className="col-sm-12">
                        <input
                          type="number"
                          name="Price"
                          onChange={(event) => handleFieldChange(event)}
                          className="form-control"
                          value={tempDefaultdata.Price ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <DateField
                        date={trialEndDate}
                        labelName={"Trial End Date"}
                        labelClass={"col-sm-12 col-form-label"}
                        dateInputclass={"form-control"}
                        dateContainerClass={"col-sm-12"}
                        onChangeDate={updateStartDate}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Room Alyzer Id
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="text"
                          name="RoomAlyzerId"
                          onChange={(event) => handleFieldChange(event)}
                          className="form-control"
                          value={tempDefaultdata.RoomAlyzerId ?? ""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        WorkTime Version
                      </label>
                      <div className="col-sm-12">
                        {/*} <input
                            type="number"
                            name="WorkTimeVersion"
                            onChange={(event) => handleFieldChange(event)}
                            className="form-control"
                            value={tempDefaultdata.WorkTimeVersion}
                        />*/}

                        <select
                          name="WorkTimeVersion"
                          onChange={(event) => onWorkTimeVersionChange(event)}
                          className="form-control"
                          value={tempDefaultdata.WorkTimeVersion ?? ""}
                        >
                          <option value="None">None</option>
                          <option value="Free">Free</option>
                          <option value="Light">Light</option>
                          <option value="Pro">Pro</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Is Trial
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.IsTrial}
                          name="IsTrial"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.IsTrial}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">Active</label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          name="Active"
                          defaultChecked={tempDefaultdata.Active}
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.Active}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Allow Specify Seat
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.AllowSpecifySeat}
                          name="AllowSpecifySeat"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.AllowSpecifySeat}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Use Delegated Authentication Only
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={
                            tempDefaultdata.UseDelegatedAuthenticationOnly
                          }
                          name="UseDelegatedAuthenticationOnly"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.UseDelegatedAuthenticationOnly}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Manager Booking
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          name="EnableManagerBooking"
                          defaultChecked={tempDefaultdata.EnableManagerBooking}
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableManagerBooking}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Meeting Room
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.EnableMeetingRoom}
                          name="EnableMeetingRoom"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableMeetingRoom}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Canteen
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          name="EnableCanteen"
                          defaultChecked={tempDefaultdata.EnableCanteen}
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableCanteen}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Desk Booking
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.EnableDeskBooking}
                          name="EnableDeskBooking"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableDeskBooking}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Parking
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.EnableParking}
                          name="EnableParking"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableParking}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Guest Bookings
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.EnableGuestBookings}
                          name="EnableGuestBookings"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableGuestBookings}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Sensors
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.EnableSensors}
                          name="EnableSensors"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableSensors}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Colleague Booking
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={
                            tempDefaultdata.EnableColleagueBooking
                          }
                          name="EnableColleagueBooking"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.EnableColleagueBooking}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Show Guest registration Link
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={
                            tempDefaultdata.ShowGuestregistrationLink
                          }
                          name="ShowGuestregistrationLink"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.ShowGuestregistrationLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Show Infoscreen Link
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.ShowInfoscreenLink}
                          name="ShowInfoscreenLink"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.ShowInfoscreenLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Show Analytics Link
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.ShowAnalyticsLink}
                          name="ShowAnalyticsLink"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.ShowAnalyticsLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Show Planner Link
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.ShowPlannerLink}
                          name="ShowPlannerLink"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.ShowPlannerLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Mobile Menu Show QR
                      </label>
                      <div className="col-sm-12">
                        <input
                          style={{ cursor: "pointer" }}
                          type="checkbox"
                          defaultChecked={tempDefaultdata.MobileMenuShowQR}
                          name="MobileMenuShowQR"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.MobileMenuShowQR}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Mobile Menu Show Checkin
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={tempDefaultdata.MobileMenuShowCheckin}
                          name="MobileMenuShowCheckin"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.MobileMenuShowCheckin}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Add bookings in outlook
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={
                            tempDefaultdata.SaveReservationsInExchange
                          }
                          name="SaveReservationsInExchange"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.SaveReservationsInExchange}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Allow Auto Select Same Seat
                      </label>
                      <div className="col-sm-12">
                        <input
                          type="checkbox"
                          defaultChecked={
                            tempDefaultdata.AllowAutoSelectSameSeat
                          }
                          name="AllowAutoSelectSameSeat"
                          onChange={(event) => handleFieldChange(event)}
                          value={tempDefaultdata.AllowAutoSelectSameSeat}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Pre register registered guests
                      </label>
                      <div className="col-sm-12">
                        <input
                          defaultChecked={
                            tempDefaultdata.GuestRegistrationPreRegisterGuestsRegistered
                          }
                          value={
                            tempDefaultdata.GuestRegistrationPreRegisterGuestsRegistered
                          }
                          type="checkbox"
                          name="GuestRegistrationPreRegisterGuestsRegistered"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Pre register meeting guests
                      </label>
                      <div className="col-sm-12">
                        <input
                          defaultChecked={
                            tempDefaultdata.GuestRegistrationPreRegisterGuestsMeetings
                          }
                          value={
                            tempDefaultdata.GuestRegistrationPreRegisterGuestsMeetings
                          }
                          type="checkbox"
                          name="GuestRegistrationPreRegisterGuestsMeetings"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable WorkTime
                      </label>
                      <div className="col-sm-12">
                        <input
                          checked={tempDefaultdata.FeatureEnableWorkTime}
                          value={tempDefaultdata.FeatureEnableWorkTime}
                          type="checkbox"
                          name="FeatureEnableWorkTime"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Guest Registration
                      </label>
                      <div className="col-sm-12">
                        <input
                          checked={
                            tempDefaultdata.FeatureEnableGuestRegistration
                          }
                          value={tempDefaultdata.FeatureEnableGuestRegistration}
                          type="checkbox"
                          name="FeatureEnableGuestRegistration"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Planner
                      </label>
                      <div className="col-sm-12">
                        <input
                          checked={tempDefaultdata.FeatureEnablePlanner}
                          value={tempDefaultdata.FeatureEnablePlanner}
                          type="checkbox"
                          name="FeatureEnablePlanner"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <div className="form-group row">
                      <label className="col-sm-12 col-form-label">
                        Enable Analytics
                      </label>
                      <div className="col-sm-12">
                        <input
                          checked={tempDefaultdata.FeatureEnableAnalytics}
                          value={tempDefaultdata.FeatureEnableAnalytics}
                          type="checkbox"
                          name="FeatureEnableAnalytics"
                          onChange={(event) => handleFieldChange(event)}
                          className="chekbox"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 p-0 mb-4 mt-3">
              <h6 className="form-heading">Meeting Share User Detail</h6>
            </div>
            <div className="row sec-spacing">
              <div className="col-md-6 col-sm-12">
                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    {!tempDefaultdata.UserName ? (<span className="text-danger" >* </span>) : ""}
                    User Name
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="text"
                      name="UserName"
                      onChange={(event) => handleFieldChange(event)}
                      className="form-control"
                      value={tempDefaultdata.UserName}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-12 col-form-label">
                    {!password ? (<span className="text-danger" >* </span>) : ""}
                    Password
                  </label>
                  <div className="col-sm-12">
                    <input
                      type="password"
                      name="Password"
                      onBlur={handelPasswordonblur}
                      onChange={(event) => handlePasswordClick(event)}
                      className="form-control"
                      value={password}
                    />
                  </div>
                </div>
              </div>
            </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-secondary"
          onClick={() => props?.onCloseClick()}
        >
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-blue"
          disabled={
            !emailSenderAddress ||
            tempDefaultdata.MaxDaysOfBookingAhead <= 0 || //!tempDefaultdata.WorkFromHomeText
            !tempDefaultdata.ShowAppointmentsFromExchange ||
            tempDefaultdata.AnonymizeAfterDays < 0 ||
            tempDefaultdata.WorkHoursStart <= 0 ||
            tempDefaultdata.WorkHoursEnd <= 0 ||
            !subDomain ||
            !tempDefaultdata.Name ||
            //tempDefaultdata.Price <= 0 ||
            tempDefaultdata.MaximumBookingsAllowed <= 0
          }
          onClick={EditTenant}
        >
          Save
        </button>
      </div>
    </>
  );
}
export default EditForm;
