import { useEffect, useRef } from 'react'
import Alert from 'react-bootstrap/Alert';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./config";

  const SignIn = () => {
  
    const { instance } = useMsal();

    const handleLogin = () => {
        instance.loginRedirect(loginRequest).catch(e => {
            console.log(e);
        });
    }

    useEffect(()=>{
        handleLogin();
    },[])

    return (
      <div>
       <Alert variant='secondary'>
        Please wait we  redirecting you to login.....
       </Alert>
      </div>
    );
  };
  

  export default SignIn;