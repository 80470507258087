import { useState, useEffect } from "react";
//import axios from "axios";
//import logo from "./logo.svg";
import "./App.css";
import { RouterProvider } from "react-router-dom";
import AppRouter from "./AppRouter";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./msal/config";
import { tokenScope, graphScope } from "./msal/config";
import mainService from "./service/main-service";


function App() {
  const [theme, setTheme] = useState({ primaryColor: "", secondaryColor: "" });

  useEffect(() => {
    async function fetchThemeData() {
      const themeResponce: any = await mainService.getTheme();
      setTheme({
        primaryColor: "#" + themeResponce.AppThemeColor,
        secondaryColor: "#" + themeResponce.TeamsMeetingColor,
      });
    }
    fetchThemeData();
  }, []);

  const msalInstance = new PublicClientApplication(msalConfig);
  const accounts = msalInstance.getAllAccounts();

  async function getAccessToken() {
    if (accounts.length > 0) {
      const request = {
        scopes: [tokenScope],
        account: accounts[0],
      };
      const accessToken = await msalInstance
        .acquireTokenSilent(request)
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
      if (accessToken != null) {
        //console.log("accessToken", accessToken);
        localStorage.setItem("token", accessToken);
      }

      //Graph token
      const graphToken = await msalInstance
        .acquireTokenSilent({
          scopes: [graphScope],
          account: accounts[0],
        })
        .then((response) => {
          return response.accessToken;
        })
        .catch((error) => {
          console.log(error);
          return null;
        });
      if (graphToken != null) {
        //console.log("accessToken", accessToken);
        localStorage.setItem("graphtoken", graphToken);
      }
      return accessToken;
    }
    return null;
  }
  getAccessToken();

  useEffect(() => {
    // update CSS variables
    document.documentElement.style.setProperty(
      "--primary-color",
      theme.primaryColor
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      theme.secondaryColor
    );
  }, [theme]);

  return <RouterProvider router={AppRouter} />;
}

export default App;
