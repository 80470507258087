import {intialStateType,filterListRespons} from "../dtos/filters-response";
import { createSlice,PayloadAction } from "@reduxjs/toolkit";

const initialTodoState:intialStateType={
    //employeelist:[],
    locations:[],
    tenant:[]
    //meetingRooms:[],
    //facilitiesList:[],
    //meetings:[], 
}




const filterSlice=createSlice({
    name:'todo',
    initialState:initialTodoState,
    reducers:{
        // setEmployeeList(state,action:PayloadAction<filterListRespons[]>){
        //     state.employeelist=action.payload;
        // },
        setLocationList(state,action:PayloadAction<filterListRespons[]>){
            state.locations=action.payload;
        },
        setTenantList(state,action:PayloadAction<filterListRespons[]>){
            state.tenant=action.payload;
        },

        
        // setMeetingRoomsList(state,action:PayloadAction<filterListRespons[]>){
        //     state.meetingRooms = action.payload
        // },
        // setfacilitiesList(state,action:PayloadAction<filterListRespons[]>){
        //     state.facilitiesList = action.payload
        // },
        // setMeetingsList(state,action:PayloadAction<filterListRespons[]>){
        //     state.meetings = action.payload
        // }
    },
   
})
export default filterSlice;